import React, { Component } from "react";
import FindDoctor from "./Elements/FindDoctor";

export default class Home extends Component {
  render() {
    const { history } = this.props;
    return (
      <div className="homePage">
        <FindDoctor history={history} />
      </div>
    );
  }
}
