import React from "react";

import ModelFormPopup from "./Elements/ModelFormPopup";

import { LogMessage, ClearToken } from "../utils";
import ApiService from "../ApiService";
import BaseComponent from "./BaseComponent";

// for google map
import GoogleMapReact from "google-map-react";

const ERROR = window.ERROR;
const END_POINTS = window.END_POINTS;
const SEARCH_PAGE_STRINGS = window.SEARCH_PAGE_STRINGS;
const ENV = window.ENV;

const Marker = (props) => {
  return <div className="mapMarker"></div>;
};

class DoctorsProfile extends BaseComponent {
  state = {
    openModel: false,
    doctorData: null,
    doctorId: null,
    loading: true,
    error: false,
    errorMsg: null,
    cellLoading: false,
    cellStatus: null,
    cellResponse: "",
    mapCenter: {
      lat: null,
      lng: null,
    },
    mapZoom: 11,
    isGeolocationAvailable: false,
    isGeolocationEnabled: false,
    coords: null
  };

  componentDidMount() {
    super.componentDidMount();
    // for get current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getPosition);
    }
  }

  getPosition  = (position) => { 
    console.log('location is', position.coords); 
    this.setState({
      coords: position.coords,
      isGeolocationAvailable: true,
      isGeolocationEnabled: true,
    })
  }

  getCurrentLocation() {
    return !this.state.isGeolocationAvailable ? (
      <div className="LinkRelation">
        <a
          href={`${END_POINTS.GOOGLE_MAP_BLANK_REDIRECT}${this.state.mapCenter.lat},${this.state.mapCenter.lng}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {SEARCH_PAGE_STRINGS.GET_DIRECTION}
        </a>
      </div>
    ) : !this.state.isGeolocationEnabled ? (
      <div className="LinkRelation">
        <a
          href={`${END_POINTS.GOOGLE_MAP_BLANK_REDIRECT}${this.state.mapCenter.lat},${this.state.mapCenter.lng}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {SEARCH_PAGE_STRINGS.GET_DIRECTION}
        </a>
      </div>
    ) : this.state.coords ? (
      <div className="LinkRelation">
        <a
          href={`${END_POINTS.GOOGLE_MAP_REDIRECT}${this.state.coords.latitude},${this.state.coords.longitude}/${this.state.mapCenter.lat},${this.state.mapCenter.lng}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {SEARCH_PAGE_STRINGS.GET_DIRECTION}
        </a>
      </div>
    ) : (
      <div className="LinkRelation">
        {SEARCH_PAGE_STRINGS.GETTING_LOCATION}&hellip;
      </div>
    );
  }

  openRequestModel = () => {
    super.openRequestModel();
  };

  goBack = () => {
    super.goBack();
  };

  removeCellStatus() {
    setTimeout(() => {
      this.setState({
        cellStatus: null,
        cellResponse: "",
      });
    }, 3000);
  }

  parseData(response) {
    if (response.status === true) {
      this.setState({
        cellLoading: false,
        cellStatus: response.status,
        cellResponse: response.message,
      });
      this.removeCellStatus();
    } else {
      this.setState({
        cellLoading: false,
        cellStatus: response.status,
        cellResponse: response.message,
      });
      this.removeCellStatus();
      ClearToken();
      this.props.history.push("/Login");
    }
  }

  render() {
    return (
      <div id="modelPop" className="detailsModal">
        <div
          className="modelOverlay"
          id="modelOverlay"
          onClick={this.props["onModelClose"]}
        />
        <div className="stylesdetailsModal">
          <div>
            <div className="modelPopup dtlsModel">
              <section className="contentArea">
                <section className="rightSection fullWidth">
                  <div className="row d-flex">
                    <div className="resultColumn fullWidth detailedPage">
                      <div className="pdng">
                        {this.state.loading && (
                          <div className="loading loadingInline">
                            <img src={SEARCH_PAGE_STRINGS.LOADING_IMG} alt="" />
                          </div>
                        )}
                        {this.state.error && (
                          <div className="error">{this.state.errorMsg}</div>
                        )}
                        {this.__doctorDetailsDiv()}
                      </div>
                    </div>
                  </div>
                </section>
                {this.__doctorRequestModel()}
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Get Doctors List Function
  loadPageData = async () => {
    const id = this.props.DoctorID;
    if (id) {
      try {
        const response = await ApiService.SendGetCall(
          END_POINTS.DOCTOR_DETAIL + id
        );
        if (this._isMounted) {
          this.setState({
            doctorData: response,
            loading: false,
            mapCenter: {
              lat: response["latitude"],
              lng: response["longitude"],
            },
          });

          /* istanbul ignore next */
          BaseComponent.goToTop();
        }
      } catch (e) {
        LogMessage(e.message);
        if (this._isMounted) {
          this.setState({
            error: true,
            errorMsg: ERROR.NODATAFOUND,
            loading: false,
          });
        }
      }
    }
  };

  __doctorDetailsDiv = () => {
    const { lat, lng } = this.state.mapCenter;
    if (this.state.doctorData !== null) {
      const {
        Full_Name__c,
        MailingAddress,
        Phone,
        Fax,
        Website_Provider_Specialties__c,
        Medical_Schools__c,
        Internships__c,
        Residencies__c,
        Board_Certifications__c,
        Provider_Image__c,
        Import_website__c,
        Available_for_Referral__c,
        Fellowships__c,
        Privileges__c,
        Jellison_Members__c,
        Graduate_School__c,
        Additional_Credentials__c
      } = this.state.doctorData;
      const id = this.props.DoctorID;
      return (
        <div>
          <figure dangerouslySetInnerHTML={{ __html: Provider_Image__c }} />
          <div className="doctorInfo">
            <h3>{Full_Name__c}</h3>
           {Additional_Credentials__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_CREDENTIALS}</strong> {Additional_Credentials__c}{" "}
              </p>
            )}
            {Phone && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_TEL}</strong> {Phone}
              </p>
            )}
            {Fax && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_FAX}</strong> {Fax}{" "}
              </p>
            )}
            {this.props.isLogin && (
              <div className="row btnGroup sendsmsBtn">
                <button
                  className="btnBlue smsBtn"
                  type="button"
                  disabled={this.state.cellLoading ? "disabled" : ""}
                  onClick={() => this.sendCell(id)}
                >
                  {this.state.cellLoading
                    ? SEARCH_PAGE_STRINGS.LOADING
                    : SEARCH_PAGE_STRINGS.BTN_SEND_CELL}
                </button>
                {this.state.cellStatus !== null && (
                  <div
                    className={
                      "loginStatus " +
                      (this.state.cellStatus === true ? "isSuccess" : "isError")
                    }
                  >
                    {this.state.cellResponse}
                  </div>
                )}
              </div>
            )}
            {MailingAddress !== null && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_ADDRESS} </strong>
                {MailingAddress["street"]},&nbsp;
                {MailingAddress["city"]}&nbsp;
                {MailingAddress["state"]}&nbsp;
                {MailingAddress["country"]}&nbsp;
                {MailingAddress["postalCode"]}
              </p>
            )}
            {Website_Provider_Specialties__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_SPECIALTIES}</strong>{" "}
                {Website_Provider_Specialties__c}
              </p>
            )}
            {Privileges__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_Privileges}</strong>{" "}
                {Privileges__c}
              </p>
            )}
            {Jellison_Members__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_Jellison}</strong>{" "}
                {Jellison_Members__c}
              </p>
            )}

            {Board_Certifications__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_BOARD_CERTIFICATION}</strong>{" "}
                {Board_Certifications__c}
              </p>
            )}

            {Graduate_School__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_GRADUATE_SCHOOL}</strong>{" "}
                {Graduate_School__c}
              </p>
            )}
            {Medical_Schools__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_MEDICAL_SCHOOL}</strong>{" "}
                {Medical_Schools__c}
              </p>
            )}

            {Internships__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_INTERNSHIP}</strong>{" "}
                {Internships__c}
              </p>
            )}

            {Residencies__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_RESIDENCY}</strong>{" "}
                {Residencies__c}
              </p>
            )}

            {Fellowships__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_FELLOWSHIP}</strong>{" "}
                {Fellowships__c}
              </p>
            )}
            {Import_website__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_WEBSITE}</strong>{" "}
                <a
                  href={`http://${Import_website__c}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {Import_website__c}
                </a>
              </p>
            )}
            <div className="row btnGroup desktopBtn">
              {Available_for_Referral__c === "Yes" && (
                <button className="btnDark" onClick={this.openRequestModel}>
                  <i className="fas fa-thumbtack" />
                  &nbsp;
                  {SEARCH_PAGE_STRINGS.REQUEST_BTN}
                </button>
              )}
              <a href={"tel://" + Phone} className="btnBlue phoneBtn">
                <i className="fas fa-phone-alt" />
                &nbsp;
                {SEARCH_PAGE_STRINGS.BTN_CALL_DOCTOR}
              </a>
              <button className="btnBlue" onClick={this.props.detailsGoBack}>
                {SEARCH_PAGE_STRINGS.BACK_BTN}
              </button>
            </div>

            <div className="mapItems">
              <GoogleMapReact
                bootstrapURLKeys={{ key: ENV.GOOGLE_MAP_API }}
                center={this.state.mapCenter}
                defaultZoom={this.state.mapZoom}
              >
                <Marker lat={lat} lng={lng} />
              </GoogleMapReact>

              {this.getCurrentLocation()}
            </div>
          </div>
        </div>
      );
    }
  };

  __doctorRequestModel = () => {
    if (this.state.openModel && this.state.doctorData !== null) {
      const { Full_Name__c } = this.state.doctorData;
      return (
        <ModelFormPopup
          onModelClose={this.closeRequestModel}
          DoctorName={Full_Name__c}
          DoctorID={this.props.DoctorID}
        />
      );
    }
  };
}
export default DoctorsProfile;
