/**
 ******************************************************************************
 * @file    BaseComponent.js
 * @brief   This Class is Base file for all Components.
 ******************************************************************************
 */

import { Component } from "react";

import {
  ClearToken,
  DisableScroll,
  EnableScroll,
  LogMessage,
  SetToken,
  CheckResponse,
} from "../utils";
import ApiService from "../ApiService";

const END_POINTS = window.END_POINTS;
const SUCCESS = window.SUCCESS;
const ERROR = window.ERROR;

export default class BaseComponent extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this._isMounted = false;

    this.closeRequestModel = this.closeRequestModel.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadPageData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadPageData() {}

  // Go to Top with iframe PostMessage
  static goToTop() {
    window.parent.postMessage &&
      window.parent.postMessage("parentScroll", "https://www.smh.com");
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    return null;
  }

  openRequestModel() {
    this.setState({ openModel: true });
    /* istanbul ignore next */
    BaseComponent.goToTop();
    DisableScroll();
  }

  closeRequestModel = () => {
    this.setState({
      openModel: false,
    });
    EnableScroll();
  };

  opendoctorDetailsModel = (DoctorId) => {
    this.setState({
      DoctorId,
      doctorDetailsModel: true,
    });
    /* istanbul ignore next */
    BaseComponent.goToTop();
    DisableScroll();
  };

  detailsGoBack = () => {
    this.setState({
      doctorDetailsModel: false,
    });
    EnableScroll();
  };

  parseLoginData(result) {
    if (result.status === true) {
      this.setState({
        status: result["status"],
        responseMsg: SUCCESS.LOGIN_SUCCESS,
        loading: false,
      });
    } else {
      this.setState({
        status: result["status"],
        responseMsg: result["message"],
        loading: false,
      });
    }
  }

  async loginDoctor(providerID, phone) {
    try {
      const result = await ApiService.LoginDoctor(providerID, phone);
      this.parseLoginData(result);
      // Set token result
      const resultTokenCheck = CheckResponse(result);
      SetToken(resultTokenCheck);
    } catch (e) {
      LogMessage(ERROR.LOGIN_ERROR);
      ClearToken();
    }
  }

  sendCell = async (id) => {
    this.setState({ cellLoading: true });
    if (id) {
      const response = await ApiService.SendPostCall(
        END_POINTS.SEND_CELL,
        { id },
        true
      );
      this.parseData(response);
    }
  };
}
