import React from "react";

const SEARCH_PAGE_STRINGS = window.SEARCH_PAGE_STRINGS;

export default (props) => {
  const { data } = props;
  const {
    Id,
    Full_Name__c,
    Additional_Credentials__c,
    MailingAddress,
    Phone,
    Fax,
    Website_Provider_Specialties__c,
    Board_Certifications__c,
    Provider_Image__c,
    Import_website__c,
    Available_for_Referral__c,
    Privileges__c,
    Jellison_Members__c
  } = data;
  return (
    <>
      <div className="resultColumn">
        <div className="pdng">
          <figure dangerouslySetInnerHTML={{ __html: Provider_Image__c }} />
          <div className="doctorInfo">
            <h3>{Full_Name__c}</h3>
            {Additional_Credentials__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_CREDENTIALS}</strong> {Additional_Credentials__c}{" "}
              </p>
            )}
            {Phone && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_TEL}</strong> {Phone}{" "}
              </p>
            )}
             {Fax && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_FAX}</strong> {Fax}{" "}
              </p>
            )}
            {MailingAddress && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_ADDRESS}</strong>{" "}
                {MailingAddress["street"]}, {MailingAddress.city}{" "}
                {MailingAddress.state} {MailingAddress.country}{" "}
                {MailingAddress.postalCode}
              </p>
            )}
            {Website_Provider_Specialties__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_SPECIALTIES}</strong>{" "}
                {Website_Provider_Specialties__c}
              </p>
            )}
            {Privileges__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_Privileges}</strong>{" "}
                {Privileges__c}
              </p>
            )}
            {Jellison_Members__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_Jellison}</strong>{" "}
                {Jellison_Members__c}
              </p>
            )}
            {Board_Certifications__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_BOARD_CERTIFICATION}</strong>{" "}
                {Board_Certifications__c}
              </p>
            )}
            {Import_website__c && (
              <p>
                <strong>{SEARCH_PAGE_STRINGS.TXT_WEBSITE}</strong>{" "}
                <a
                  href={`http://${Import_website__c}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {Import_website__c}
                </a>
              </p>
            )}
            <div className="row btnGroup">
              {props.isLogin && (
                <button
                  className="btnBlue smsBtn"
                  type="button"
                  disabled={props.cellLoading ? "disabled" : ""}
                  onClick={() => props.sendCell(Id)}
                >
                  {SEARCH_PAGE_STRINGS.BTN_SEND_CELL}
                </button>
              )}
              {Available_for_Referral__c === "Yes" && (
                <button
                  className="btnDark"
                  onClick={() => props.openRequestModel(Full_Name__c, Id)}
                >
                  <i className="fas fa-thumbtack" />
                  &nbsp;
                  {SEARCH_PAGE_STRINGS.REQUEST_BTN}
                </button>
              )}
              <button
                className="btnBlue"
                onClick={() => props.opendoctorDetailsModel(Id)}
              >
                {SEARCH_PAGE_STRINGS.DETAIL_BTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
