import React, { Component } from "react";
import ApiService from "../../ApiService";
import { LogMessage } from "./../../utils";
const REGEX_PATTERN = window.REGEX_PATTERN;

const validEmailRegex = REGEX_PATTERN.EMAIL;
const phoneNumberPattern = REGEX_PATTERN.PHONE;

const END_POINTS = window.END_POINTS;

const MODEL_FORM_STRINGS = window.MODEL_FORM_STRINGS;
const ERROR = window.ERROR;

export default class ModelFormPopup extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    reason: "",
    status: null,
    responseMsg: "",
    loading: false,
    errors: {
      name: "",
      email: "",
      phone: "",
      reason: "",
    },
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  sendRequest = async () => {
    if (typeof this.props.DoctorID !== "undefined") {
      this.setState({ loading: true });
      const params = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.reason,
      };

      try {
        const response = await ApiService.SendPostCall(
          END_POINTS.REQUEST_APPOINTMENT + "/" + this.props.DoctorID,
          params,
          false
        );
        this.parseData(response);
      } catch (e) {
        LogMessage("Error in Appointment Form Submit");
      }
    }
  };

  parseData(response) {
    if (response.status === true) {
      this.setState({
        status: response.status,
        responseMsg: response.message,
        loading: false,
        name: "",
        email: "",
        phone: "",
        reason: "",
      });
    } else {
      this.setState({
        status: response.status,
        responseMsg: response.message,
        loading: false,
      });
    }
  }

  handleUserInput = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "name":
        errors.name = value.length < 1 ? ERROR.MODEL_FORM_NAME : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : ERROR.MODEL_FORM_EMAIL;
        break;
      case "phone":
        errors.phone = phoneNumberPattern.test(value)
          ? ""
          : ERROR.MODEL_FORM_PHONE;
        break;
      case "reason":
        errors.reason = value.length < 1 ? ERROR.MODEL_FORM_REASON : "";
        break;
      default:
    }
    this.setState({ errors, [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm(this.state.errors)) {
      this.sendRequest();
    } else {
      this.setState({
        status: false,
        responseMsg: ERROR.INVALID_INPUT,
        loading: false,
      });
    }
  };

  render() {
    const { DoctorName } = this.props;
    const { errors } = this.state;

    return (
      <div id="modelPop" className="modelPopupContainer">
        <div
          className="modelOverlay"
          id="modelOverlay"
          onClick={this.props["onModelClose"]}
        />
        <div className="stylesModal">
          <div>
            <div className="modelPopup appointmentModel">
              <h2>
                {MODEL_FORM_STRINGS.MODEL_TITLE} <br />
                <span>{DoctorName}</span>
              </h2>
              {this.state.status !== null && (
                <div
                  className={
                    "loginStatus " +
                    (this.state.status === true ? "isSuccess" : "isError")
                  }
                >
                  {this.state.responseMsg}
                </div>
              )}
              <form noValidate>
                <div className="formDiv">
                  <label htmlFor="name">
                    {MODEL_FORM_STRINGS.LABEL_NAME}&nbsp;
                    <span>{MODEL_FORM_STRINGS.MANDATORY}</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={MODEL_FORM_STRINGS.NAME_PLACEHOLDER}
                    value={this.state.name}
                    className={errors.name.length > 0 ? "error" : ""}
                    onChange={this.handleUserInput}
                    noValidate
                  />
                  {errors.name.length > 0 && (
                    <span className="error">{errors.name}</span>
                  )}
                </div>
                <div className="formDiv">
                  <label htmlFor="email">
                    {MODEL_FORM_STRINGS.LABEL_EMAIL}&nbsp;
                    <span>{MODEL_FORM_STRINGS.MANDATORY}</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={MODEL_FORM_STRINGS.EMAIL_PLACEHOLDER}
                    value={this.state.email}
                    className={errors.email.length > 0 ? "error" : ""}
                    onChange={this.handleUserInput}
                    noValidate
                  />
                  {errors.email.length > 0 && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="formDiv">
                  <label htmlFor="phone">
                    {MODEL_FORM_STRINGS.LABEL_PHONES}&nbsp;
                    <span>{MODEL_FORM_STRINGS.MANDATORY}</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={MODEL_FORM_STRINGS.PHONES_PLACEHOLDER}
                    value={this.state.phone}
                    className={errors.phone.length > 0 ? "error" : ""}
                    onChange={this.handleUserInput}
                    noValidate
                  />
                  {errors.phone.length > 0 && (
                    <span className="error">{errors.phone}</span>
                  )}
                </div>
                <div className="formDiv">
                  <label htmlFor="reason">
                    {MODEL_FORM_STRINGS.LABEL_APPOINTMENT}&nbsp;
                    <span>{MODEL_FORM_STRINGS.MANDATORY}</span>
                  </label>
                  <textarea
                    name="reason"
                    id="reason"
                    value={this.state.reason}
                    className={errors.reason.length > 0 ? "error" : ""}
                    onChange={this.handleUserInput}
                    noValidate
                  ></textarea>
                  {errors.reason.length > 0 && (
                    <span className="error">{errors.reason}</span>
                  )}
                </div>
                <div className="formBtnGroup">
                  <button
                    className="btnSubmit"
                    type="submit"
                    disabled={
                      this.state.loading === false &&
                      this.state.email !== "" &&
                      this.state.phone !== "" &&
                      this.state.reason !== ""
                        ? ""
                        : "disabled"
                    }
                    onClick={this.handleSubmit}
                  >
                    {this.state.loading
                      ? MODEL_FORM_STRINGS.LOADING
                      : MODEL_FORM_STRINGS.REQUEST_BTN}
                  </button>
                  <button
                    className="btnClose"
                    type="button"
                    onClick={this.props["onModelClose"]}
                  >
                    {MODEL_FORM_STRINGS.CLOSE_BTN}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
