import React from "react";
import BaseComponent from "./BaseComponent";
import FindDoctor from "./Elements/FindDoctor";

export default class FindDoctorOrProvider extends BaseComponent {
  render() {
    const { history } = this.props;
    return (
      <section className="contentArea">
        <div className="rightSection floatRight fullWidth">
          <div className="row d-flex">
            <FindDoctor history={history} />
          </div>
        </div>
      </section>
    );
  }
}
