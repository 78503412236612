/**
 ******************************************************************************
 * @file    UTILS.js
 * @brief   This file used for different utility functions.
 ******************************************************************************
 */
const ENV = window.ENV;

export const UpdateDocumentTitle = (title) => {
  /* istanbul ignore next */
  setTimeout(() => {
    document.getElementById("MobilePageTitle").innerHTML = title;
  }, 100);
};

export const LogMessage = (message) => {
  console.log(message);
};

export function CheckResponse(response) {
  if (response.status !== true) {
    throw response["message"];
  }
  return response["message"];
}

export function SetToken(token) {
  try {
    if ("localStorage" in window && window.localStorage !== null) {
      localStorage.setItem("token", token);
    } else {
      LogMessage("localStorage not supported");
    }
  } catch (ex) {
    LogMessage(ex);
  }
}

export function GetUserToken(token) {
  try {
    if ("localStorage" in window && window.localStorage !== null) {
      return localStorage.getItem("token") || null;
    } else {
      LogMessage("localStorage not supported");
    }
  } catch (ex) {
    LogMessage(ex);
  }
}

export function ClearToken() {
  try {
    if ("localStorage" in window && window.localStorage !== null) {
      return localStorage.clear();
    } else {
      LogMessage("localStorage not supported");
    }
  } catch (ex) {
    LogMessage(ex);
  }
}

export function DisableScroll() {
  const windowBody = document.getElementsByTagName("body")[0];
  const windowHTML = document.getElementsByTagName("html")[0];
  windowBody.classList.add("scrollDisable");
  windowHTML.classList.add("scrollDisable");
}

export function EnableScroll() {
  const windowBody = document.getElementsByTagName("body")[0];
  const windowHTML = document.getElementsByTagName("html")[0];
  windowBody.classList.remove("scrollDisable");
  windowHTML.classList.remove("scrollDisable");
}

/**
 * Get the Referer URL.
 *
 * @returns {string}
 */
export const getRefererURL = () => {
  const allowedUrls = [...ENV.SMH_SITES, ...ENV.VENICE_SITES];
  let referer = 'www.smh.com';
  allowedUrls.forEach(function (value) {
    if ('' !== window.location.search && -1 !== window.location.search.indexOf(value)) {
      referer = value;
    }
  });
  return referer;
}

/**
 * Get the Form label based on the referer.
 *
 * @returns {string}
 */
export const getRefererFormLabel = () => {
  return isRefererSMH() ? 'Find a Doctor or Provider' : 'Find a Doctor or Provider at the Venice Campus';
}

/**
 * Check if the referer URL is smh.com.
 *
 * @returns {bool}
 */
export const isRefererSMH = () => {
  let isSMH = true;
  ENV.VENICE_SITES.forEach(function (value) {
    if ('' !== window.location.search && -1 !== window.location.search.indexOf(value)) {
      isSMH = false;
    }
  });
  return isSMH;
}
