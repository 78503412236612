import React from "react";

import BaseComponent from "./BaseComponent";
import { getRefererURL } from "../utils";

const PAGES_PATH = window.PAGES_PATH;
const LOGINPAGE = window.LOGINPAGE;
const ERROR = window.ERROR;
const REGEX_PATTERN = window.REGEX_PATTERN;
const URL = getRefererURL();

const phoneNumberPattern = REGEX_PATTERN.PHONE;

export default class DoctorsLogin extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      phone: "",
      providerId: "",
      status: null,
      responseMsg: "",
      errors: {
        phone: "",
        providerId: "",
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.loading !== nextState.loading ||
      this.state.phone !== nextState.phone ||
      this.state.providerId !== nextState.providerId ||
      this.state.status !== nextState.status ||
      this.state.responseMsg !== nextState.responseMsg ||
      this.props.isLogin !== nextProps.isLogin
    );
  }

  componentDidMount() {
    this.isLoginRedirect();
  }

  componentDidUpdate() {
    this.isLoginRedirect();
  }

  handleUserInput = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "phone":
        errors.phone = phoneNumberPattern.test(value) ? "" : ERROR.CELLNUMBER;
        break;
      case "providerId":
        errors.providerId = value.length < 1 ? ERROR.DICTATION : "";
        break;
      default:
    }
    this.setState({ errors, [name]: value });
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  isLoginRedirect() {
    const isLogin = this.props.isLogin;
    // isLogin && this.props.history.replace(PAGES_PATH.GOTO_FINDDOCTORPROVIDER);
    if (isLogin === true) {
      window.top.location.href = `//${URL}${PAGES_PATH.GOTO_MOBILE_HOME_REDIRECT}`;
    }
  }

  handleSubmit = (providerId, phone) => {
    if (this.validateForm(this.state.errors)) {
      this.setState({ loading: true });
      this.loginDoctor(providerId, phone);
    } else {
      this.setState({
        status: false,
        responseMsg: "Invalid Form input",
        loading: false,
      });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <section className="contentArea">
        <div className="row d-flex">
          <div className="doctorsLogin">
            <h1>{LOGINPAGE.LOGIN_TITLE}</h1>

            {this.state.status !== null && (
              <div
                className={
                  "loginStatus " +
                  (this.state.status === true ? "isSuccess" : "isError")
                }
              >
                {this.state.responseMsg}
              </div>
            )}

            <form noValidate>
              <div className="formDiv">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className={errors.phone.length > 0 ? "error" : ""}
                  onChange={this.handleUserInput}
                  placeholder={LOGINPAGE.CELL_PLACEHOLDER}
                />
                {errors.phone.length > 0 && (
                  <span className="error">{errors.phone}</span>
                )}
              </div>
              <div className="formDiv">
                <input
                  type="text"
                  name="providerId"
                  id="providerId"
                  className={errors.providerId.length > 0 ? "error" : ""}
                  onChange={this.handleUserInput}
                  placeholder={LOGINPAGE.DICTATION}
                />
                {errors.providerId.length > 0 && (
                  <span className="error">{errors.providerId}</span>
                )}
              </div>
              <div className="btnGroup">
                <button
                  type="button"
                  className="btnSubmit"
                  disabled={
                    this.state.loading === false &&
                    this.state.phone !== "" &&
                    this.state.providerId !== ""
                      ? ""
                      : "disabled"
                  }
                  onClick={() =>
                    this.handleSubmit(this.state.providerId, this.state.phone)
                  }
                >
                  <span>
                    {this.state.loading
                      ? LOGINPAGE.TEXT_LOADING
                      : LOGINPAGE.TEXT_SUBMIT}
                  </span>
                  &nbsp;
                  <i className="fas fa-paper-plane" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}
