import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Home from "./Components/Home";
import SearchResults from "./Components/SearchResults";
import DoctorsProfile from "./Components/DoctorsProfile";
import FindDoctorOrProvider from "./Components/findDoctorOrProvider";
import DoctorsLogin from "./Components/DoctorsLogin";
import { GetUserToken, ClearToken } from "./utils";

const PAGES_PATH = window.PAGES_PATH;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      intervalId: null,
    };
  }

  componentDidMount() {
    const intervalId = setInterval(this.checkLogin.bind(this), 500);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.isLogin !== nextState.isLogin
    );
  }

  checkLogin() {
    const token = GetUserToken();
    if (token !== null) {
      this.setState({
        isLogin: true,
      });
    } else {
      this.setState({
        isLogin: false,
      });
    }
  }

  logOut() {
    ClearToken();
    window.location.href = "/";
  }

  handleGoBack(e) {
    e.preventDefault();
    window.history.go(-1);
    return false;
  }

  render() {
    return (
      <div className="container">
        <Switch>
          <Route
            exact
            path={PAGES_PATH.GOTO_HOME}
            component={(props) => (
              <Home
                {...props}
                isLogin={this.state.isLogin}
                logOut={this.logOut}
              />
            )}
          />
          <Route
            path={PAGES_PATH.GOTO_SEARCH}
            component={(props) => (
              <SearchResults
                {...props}
                isLogin={this.state.isLogin}
              />
            )}
          />
          <Route
            path={PAGES_PATH.GOTO_FINDDOCTORPROVIDER}
            component={FindDoctorOrProvider}
          />
          <Route
            path={PAGES_PATH.GOTO_PROFILE}
            component={(props) => (
              <DoctorsProfile
                {...props}
                isLogin={this.state.isLogin}
              />
            )}
          />
          <Route
            path={PAGES_PATH.GOTO_LOGIN}
            component={(props) => (
              <DoctorsLogin {...props} isLogin={this.state.isLogin} />
            )}
          />
          <Redirect to={PAGES_PATH.GOTO_FINDDOCTORPROVIDER}></Redirect>
        </Switch>
      </div>
    );
  }
}

export default App;
