import React from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Pagination from "react-js-pagination";

import BaseComponent from "./BaseComponent";
import SearchResultItem from "./SearchResultItem";
import ModelFormPopup from "./Elements/ModelFormPopup";

import { LogMessage, ClearToken, getRefererURL } from "../utils";
import ApiService from "../ApiService";
import DoctorsProfile from "./DoctorsProfile";

const ENV = window.ENV;
const END_POINTS = window.END_POINTS;
const ERROR = window.ERROR;
const SEARCH_PAGE_STRINGS = window.SEARCH_PAGE_STRINGS;
const PAGES_PATH = window.PAGES_PATH;
const URL = getRefererURL();

export default class SearchResults extends BaseComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this._isMounted = false;

    let url = this.props.location.search;
    let params = queryString.parse(url);
    let name = null;
    let location = null;
    let specialty = null;
    let keyword = null;
    let advancesearch = null;
    let includeProviders = null;
    let veniceFlag = null;
    if (params.name !== "" && params.name !== undefined) {
      name = params.name;
    }
    if (params.location !== "" && params.location !== undefined) {
      location = params.location;
    }
    if (params.specialty !== "" && params.specialty !== undefined) {
      specialty = params.specialty;
    }
    if (params.keyword !== "" && params.keyword !== undefined) {
      keyword = params.keyword;
    }
    if (params.advancesearch !== "" && params.advancesearch !== undefined) {
      advancesearch = params.advancesearch;
    }
    if (params.system !== "" && params.system !== undefined) {
      veniceFlag = params.system;
    }
    if (
      params.includeProviders !== "" &&
      params.includeProviders !== undefined
    ) {
      includeProviders = params.includeProviders;
    }
    this.state = {
      data: null,
      openModel: false,
      doctorDetailsModel: false,
      loading: true,
      error: null,
      totalCount: null,
      name: name,
      location: location,
      specialty: specialty,
      keyword: keyword,
      advancesearch: advancesearch,
      includeProviders: includeProviders,
      veniceFlag: veniceFlag,
      page: 1,
      selectedDoctorName: "",
      DoctorId: "",
      sort: "name",
      printUrl: "",
      printSpec: "",
      printName: "",
      printLocation: "",
      printKeyword: "",
      cellLoading: false,
      cellStatus: null,
      cellResponse: "",
    };
  }

  componentDidMount() {
    // this.handleSearchStateRedirect();
    super.componentDidMount();
  }

  removeCellStatus() {
    setTimeout(() => {
      this.setState({
        cellStatus: null,
        cellResponse: "",
      });
    }, 3000);
  }

  parseData = (response) => {
    if (response.status === true) {
      this.setState({
        cellLoading: false,
        cellStatus: response.status,
        cellResponse: response.message,
      });
      this.removeCellStatus();
    } else {
      this.setState({
        cellLoading: false,
        cellStatus: response.status,
        cellResponse: response.message,
      });
      this.removeCellStatus();
      ClearToken();
      setTimeout(() => {
        this.props.history.push("/Login");
      }, 3000);
    }
  };

  handlerPrint(e, printUrl, printSpec, printName, printLocation, printKeyword) {
    e.preventDefault();
    if (window.handlerPrint) {
      window.handlerPrint(
        e,
        printUrl,
        printSpec,
        printName,
        printLocation,
        printKeyword
      );
    }
  }

  // if search state null then redirect
  handleSearchStateRedirect() {
    // Call Function for Doctors List
    if (
      this.state.name === null &&
      this.state.location === null &&
      this.state.specialty === null &&
      this.state.keyword === null
    ) {
      this.setState({
        error: ERROR.ONE_ATLEAST,
        loading: false,
      });
      // return this.props.history.push(PAGES_PATH.GOTO_FINDDOCTORPROVIDER);
      return (window.top.location.href =
      `//${URL}${PAGES_PATH.GOTO_FINDDOCTORPROVIDER_REDIRECT}`);
    }
  }

  // Get Doctors List Function
  async loadPageData() {
    const {
      name,
      location,
      specialty,
      keyword,
      advancesearch,
      veniceFlag,
      includeProviders,
      page,
      sort,
    } = this.state;
    let s_name = "";
    let s_location = "";
    let s_specialty = "";
    let s_veniceFlag = "";
    let s_keyword = "";
    let s_includeProviders = "";
    let currentComponent = this;
    if (name !== null) {
      s_name = "name=" + name + "&";
    }
    if (location !== null) {
      s_location = "location=" + location + "&";
    }
    if (specialty !== null) {
      s_specialty = "doctorSpecialty=" + specialty + "&";
    }
    if (veniceFlag !== null) {
      s_veniceFlag = "system=" + veniceFlag + "&";
    }
    if (keyword !== null) {
      s_keyword = "keyword=" + keyword + "&";
    } else {
      s_keyword = "keyword=no_value&";
    }
    const s_page = "page=" + page + "&sort=" + sort;

    // get endpoints
    let API_END_POINT;
    if (advancesearch === "1") {
      s_includeProviders = "includeProviders=" + includeProviders + "&";
      API_END_POINT = END_POINTS.DOCTOR_ADVANCE_SEARCH;
    } else {
      API_END_POINT = END_POINTS.DOCTOR_SEARCH;
    }

    // For Print Button set Url
    const printUrl =
      ENV.API_URL +
      API_END_POINT +
      "/?" +
      s_specialty +
      s_name +
      s_location +
      s_keyword +
      s_includeProviders +
      s_veniceFlag +
      "totalCount=2000";
    this.setState({
      printUrl: printUrl,
      printSpec: specialty,
      printName: name,
      printLocation: location,
      printKeyword: keyword,
    });

    if (specialty !== "") {
      try {
        const response = await ApiService.SendGetCall(
          API_END_POINT +
            "/?" +
            s_specialty +
            s_name +
            s_location +
            s_keyword +
            s_includeProviders +
            s_veniceFlag +
            s_page
        );
        this._isMounted &&
          currentComponent.setState({
            totalCount: response["TotalRecords"],
            data: response["Data"],
            loading: false,
          });
        // go to top
        /* istanbul ignore next */
        BaseComponent.goToTop();
      } catch (e) {
        LogMessage(e.message);
        currentComponent.setState({ error: ERROR.NODATAFOUND, loading: false });
      }
    } else {
      currentComponent.setState({ error: ERROR.ONE_ATLEAST, loading: false });
    }
  }

  handlePageChange = (pageNumber) => {
    LogMessage(`active page is ${pageNumber}`);
    this.setState(
      {
        page: pageNumber,
        loading: true,
      },
      () => {
        this.loadPageData();
      }
    );
  };

  handleSort = (event) => {
    if (event.target.value !== "") {
      const { name, value } = event.target;
      LogMessage("name :" + name);
      LogMessage("value :" + value);
      this.setState(
        {
          page: 1,
          sort: value,
          loading: true,
        },
        () => {
          this.loadPageData();
        }
      );
    }
  };

  openRequestModel = (selectedDoctorName, DoctorId) => {
    this.state.selectedDoctorName = selectedDoctorName;
    this.state.DoctorId = DoctorId;
    super.openRequestModel();
  };

  render() {
    const {
      specialty,
      name,
      location,
      keyword,
      totalCount,
      selectedDoctorName,
      DoctorId,
      loading,
      cellStatus,
      cellResponse,
      cellLoading,
    } = this.state;
    const count = Math.ceil(totalCount / 10);
    return (
      <section className="contentArea">
        <div className="rightSection floatRight fullWidth">
          <h1>
            {SEARCH_PAGE_STRINGS.TEXT_SEARCH} {specialty} /{" "}
            {SEARCH_PAGE_STRINGS.KEYWORD}: {name !== null && name + ","}{" "}
            {location !== null && location + ","} {keyword !== null && keyword}
          </h1>
          <p className="notLookingBtn">
            {SEARCH_PAGE_STRINGS.NOT_LOOKING}
            <a href={`//${URL}${PAGES_PATH.GOTO_FINDDOCTORPROVIDER_REDIRECT}`}>
              {SEARCH_PAGE_STRINGS.CLICKHERE}
            </a>
          </p>
          <div className="minHeight">
            {cellStatus !== null && (
              <div id="modelPop" className="modelPopupContainer">
                <div
                  className="modelOverlay"
                  id="modelOverlay"
                  onClick={this.props["onModelClose"]}
                />
                <div className="stylesModal searchresultpage">
                  <div>
                    <div className="modelPopup appointmentModel">
                      <div className="row btnGroup">
                        <div
                          className={
                            "loginStatus " +
                            (cellStatus === true ? "isSuccess" : "isError")
                          }
                        >
                          {cellResponse}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(loading || cellLoading) && (
              <div className="loading">
                <img src={SEARCH_PAGE_STRINGS.LOADING_IMG} alt="" />
              </div>
            )}
            {this.state.error !== null && (
              <div className="dataNotFound">{this.state.error}</div>
            )}

            {this.state.data !== null && (
              <div className="row d-flex" id="printDiv">
                {this.state.data.length === 0 && (
                  <div className="dataNotFound">{ERROR.NODATAFOUND}</div>
                )}
                {this.state.data.map((item, index) => (
                  <SearchResultItem
                    key={index}
                    data={item}
                    loading={loading}
                    isLogin={this.props.isLogin}
                    openRequestModel={this.openRequestModel}
                    closeRequestModel={this.closeRequestModel}
                    opendoctorDetailsModel={this.opendoctorDetailsModel}
                    sendCell={this.sendCell}
                  />
                ))}
                {this.state.openModel && (
                  <ModelFormPopup
                    onModelClose={this.closeRequestModel}
                    DoctorName={selectedDoctorName}
                    DoctorID={DoctorId}
                  />
                )}
                {this.state.doctorDetailsModel && (
                  <DoctorsProfile
                    detailsGoBack={this.detailsGoBack}
                    DoctorID={DoctorId}
                    isLogin={this.props.isLogin}
                    isGeolocationAvailable={this.props.isGeolocationAvailable}
                    isGeolocationEnabled={this.props.isGeolocationEnabled}
                    coords={this.props.coords}
                  />
                )}
              </div>
            )}
          </div>
          <div className="paginationSection">
            <div id="pagination">
              <div>
                {count > 1 && (
                  <Pagination
                    activePage={this.state.page}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.totalCount}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange}
                  />
                )}
              </div>
            </div>
            {count > 1 && (
              <div className="shortBy">
                <select onChange={this.handleSort} name="sort">
                  {SEARCH_PAGE_STRINGS.SHORT_BY_TXT.map((item, index) => (
                    <option key={index} value={item.VALUE}>
                      {item.OPTION}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {this.state.data === null ||
            (this.state.data.length === 0) ? (
              ""
            ) : (
              <div className="printBtn">
                <Link
                  to="#/"
                  onClick={(e) =>
                    this.handlerPrint(
                      e,
                      this.state.printUrl,
                      this.state.printSpec,
                      this.state.printName,
                      this.state.printLocation,
                      this.state.printKeyword
                    )
                  }
                >
                  {SEARCH_PAGE_STRINGS.PRINT}
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
