import React, { Component } from "react";
import Select from "react-select";
import ApiService from "../../ApiService";
import Switch from "react-switch";
import { LogMessage, isRefererSMH, getRefererFormLabel, getRefererURL } from "../../utils";

const SEARCH_STRINGS = window.SEARCH_STRINGS;
const ERROR = window.ERROR;
const SEARCH_PAGE_STRINGS = window.SEARCH_PAGE_STRINGS;
const END_POINTS = window.END_POINTS;
const PAGES_PATH = window.PAGES_PATH;
const IS_SMH = isRefererSMH();
const REFERER_LABEL = getRefererFormLabel();
const URL = getRefererURL();
export default class FindDoctor extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      doctorName: "",
      officeLocation: "",
      doctorSpecialty: "",
      doctorSpecialtyError: "",
      keyword: "",
      search: "",
      atLeastOne: false,
      loading: true,
      errors: {
        doctorName: "",
        officeLocation: "",
        keyword: "",
      },
      specialtyList: null,
      zipCodeList: null,
      checked: true,
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.__loadSpecialties();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  clearZipCode = () => {
    setTimeout(() => {
      this.setState({ zipCodeList: null });
    }, 300);
  };

  setZipValue = (item) => {
    this.setState({ officeLocation: item });
  };

  handleCheckboxChange = (checked) => {
    this.setState({ checked });
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="findDoctor">
        {this.state.loading && (
          <div className="loading">
            <img src={SEARCH_PAGE_STRINGS.LOADING_IMG} alt="" />
          </div>
        )}
        <div className="container">
          {this.state.atLeastOne && (
            <div className="atLeastOne">{ERROR.ONE_ATLEAST}</div>
          )}
          <form onSubmit={this.handleRedirect} noValidate autoComplete="off">
            <div className="colThree">
              <label htmlFor="findDoctor">{REFERER_LABEL}</label>
            </div>
            <div className="colThree">
              <input
                type="text"
                name="doctorName"
                className={errors.doctorName.length > 0 ? "error" : ""}
                value={this.state.doctorName}
                placeholder={SEARCH_STRINGS.NAME_PLACEHOLDER}
                onChange={this.handleUserInput}
              />
              {errors.doctorName.length > 0 && (
                <span className="error">{errors.doctorName}</span>
              )}
            </div>
            <div className="colThree">
              <Select
                name="doctorSpecialty"
                value={this.state.doctorSpecialty}
                classNamePrefix="customSelect"
                placeholder={SEARCH_STRINGS.SPECIALTY_PLACEHOLDER}
                className={
                  this.state.doctorSpecialtyError !== "" ? "error" : ""
                }
                onChange={this.handleSelectChange}
                options={this.state.specialtyList}
              />
            </div>
            <div className="colThree">
              <input
                type="text"
                name="officeLocation"
                className={errors.officeLocation.length > 0 ? "error" : ""}
                value={this.state.officeLocation}
                placeholder={SEARCH_STRINGS.LOCATION_PLACEHOLDER}
                onChange={this.handleUserInput}
                onBlur={this.clearZipCode}
              />
              {this.state.zipCodeList && (
                <ul className="zipCodeList">
                  {this.state.zipCodeList.map((item, index) => (
                    <li key={index} onClick={() => this.setZipValue(item)}>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              )}
              {errors.officeLocation.length > 0 && (
                <span className="error">{errors.officeLocation}</span>
              )}
            </div>
            <div className="colThree colKeyword">
              <input
                type="text"
                name="keyword"
                className={errors.keyword.length > 0 ? "error" : ""}
                value={this.state.keyword}
                placeholder={SEARCH_STRINGS.KEYWORD_PLACEHOLDER}
                onChange={this.handleUserInput}
              />
              {errors.keyword.length > 0 && (
                <span className="error">{errors.keyword}</span>
              )}
            </div>
            <div className="colThree colProviders">
              <span className="label">
                {SEARCH_STRINGS.ONLY_ACCEPTING_TEXT}
              </span>
              <span className="toggleSctn">
                <Switch
                  onChange={this.handleCheckboxChange}
                  checked={this.state.checked}
                />
              </span>
            </div>
            <div className="colSmall">
              <button type="submit">
                {SEARCH_STRINGS.SEARCH}&nbsp;
                <i className="fas fa-paper-plane" />
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  handleUserInput = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "doctorName":
        errors.doctorName =
          value.length < 2 && value.length > 0 ? ERROR.DOCTORERROR : "";
        break;
      case "officeLocation":
        errors.officeLocation =
          value.length < 5 && value.length > 0
            ? ERROR.LOCATIONERROR
            : this.__loadLocationList(value);
        break;
      case "keyword":
        errors.keyword =
          value.length < 2 && value.length > 0 ? ERROR.KEYWORDERROR : "";
        break;
      case "doctorSpecialty":
        if (value !== "") {
          this.setState({ doctorSpecialtyError: "" });
        }
        break;
      default:
    }
    this.setState({ errors, [name]: value });
  };

  handleSelectChange = (doctorSpecialty) => {
    if (doctorSpecialty.value !== "") {
      this.setState({
        doctorSpecialty,
      });
    }
  };
  handleSelectChangeLocation = (officeLocation) => {
    if (officeLocation.value !== "") {
      this.setState({
        officeLocation,
      });
    }
  };

  validateForm = (errors) => {
    let valid = true;
    if (
      this.state.doctorSpecialty === "" &&
      this.state.doctorName === "" &&
      this.state.officeLocation === "" &&
      this.state.keyword === ""
    ) {
      this.setState({
        atLeastOne: true,
      });
      valid = false;
    }
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  handleRedirect = (event) => {
    event.preventDefault();
    let doctorSpecialty = "";
    // let officeLocation = '';
    if (this.validateForm(this.state.errors)) {
      const doctorName = this.state.doctorName
        .replace(/\s+/g, "+")
        .toLowerCase();
      const officeLocation = this.state.officeLocation
        .replace(/\s+/g, "+")
        .toLowerCase();
      const keyword = this.state.keyword.replace(/\s+/g, "+").toLowerCase();
      if (this.state.doctorSpecialty !== "") {
        doctorSpecialty = this.state.doctorSpecialty.value
          .replace(/\s+/g, "+")
          .toLowerCase();
      }
      let advancesearch = "";
      let includeProviders = "";
      let veniceFlag = "";
      if (
        this.props.history.location.pathname ===
        PAGES_PATH.GOTO_FINDDOCTORPROVIDER
      ) {
        advancesearch = "&advancesearch=1";
        includeProviders = `&includeProviders=${this.state.checked}`;
      }
      if (!IS_SMH) {
        veniceFlag = "&system=venice";
      }
      this.setState({ search: SEARCH_STRINGS.VALID_REDIRECT });
      window.top.location.href = `//${URL}${PAGES_PATH.GOTO_SEARCH_REDIRECT}?name=${doctorName}&location=${officeLocation}&specialty=${doctorSpecialty}&keyword=${keyword}${advancesearch}${includeProviders}${veniceFlag}`;
    } else {
      if (
        this.state.doctorSpecialty === "" &&
        this.state.doctorName === "" &&
        this.state.officeLocation === "" &&
        this.state.keyword !== ""
      ) {
        this.setState({
          atLeastOne: true,
        });
      }
      this.setState({ search: SEARCH_STRINGS.NO_REDIRECT });
    }
  };

  /**
   * Tthis function is used to fetch list of specialties.
   * @return {Promise<void>}
   * @private
   */
  async __loadSpecialties() {
    if (!this.state.specialtyList) {
      try {
        const response = await ApiService.SendGetCall(
          END_POINTS.DOCTOR_SPECIALTIES_LIST
        );
        response.sort();
        const specialtyList = response.map((record) => {
          return {
            label: record,
            value: record,
          };
        });
        this._isMounted && this.setState({ specialtyList, loading: false });
      } catch (e) {
        LogMessage(e);
      }
    }
  }

  /**
   * Tthis function is used to fetch list of location List.
   * @return {Promise<void>}
   * @private
   */
  async __loadLocationList(zipCode) {
    try {
      const response = await ApiService.SendGetCall(
        END_POINTS.DOCTOR_ZIPCODE_LIST + zipCode
      );
      // order by ascending
      var zipCodeList = response.sort((item) =>
        item === this.state.officeLocation ? -1 : 1
      );
      this._isMounted && this.setState({ zipCodeList });
    } catch (e) {
      LogMessage(e);
    }
  }
}
