/**
 ******************************************************************************
 * @file    APIService.js
 * @brief   This file uses to make all API Requests.
 ******************************************************************************
 */

import axios from "axios";

import { CheckResponse, GetUserToken, LogMessage } from "./utils";

const ENV = window.ENV;
const END_POINTS = window.END_POINTS;

export default class ApiService {
  static async SendGetCall(url) {
    try {
      const URL = ENV.API_URL + url;
      const response = await axios.get(URL);
      return CheckResponse(response.data.body);
    } catch (e) {
      LogMessage("Error From API CALL " + URL);
      LogMessage(e.message);
      throw new Error(e.message);
    }
  }

  static async SendPostCall(url, params, needHeader = true) {
    try {
      let headers = {};
      if (needHeader) {
        const token = GetUserToken();
        if (!token) {
          throw new Error("No Auth token found");
        }
        headers = {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      }
      const URL = ENV.API_URL + url;
      const response = await axios.post(URL, params, headers);
      return response.data.body;
    } catch (e) {
      LogMessage("Error From API Call " + url);
    }
  }

  static async LoginDoctor(providerId, phone) {
    const URL = ENV.API_URL + END_POINTS.DOCTOR_LOGIN;
    try {
      const response = await axios.post(URL, {
        providerId,
        phone,
      });
      return response.data.body;
    } catch (e) {
      LogMessage("Error From API Call " + URL);
    }
  }
}
